<template>
  <p class="forgotPassword">
    <a href="javascript:void(0)" @click="showModal = true">Forgot password?</a>
  </p>
  <div class="modal-backdrop" @click="showModal = false" v-if="showModal">
    <div class="modal" @click.stop>
      <header class="modal-header" id="modalTitle">
        <h2>Forgot password</h2>
      </header>
      <form class="modal-body" @submit.prevent="handleSubmit">
        <div class="formItem">
          <label for="emailForPassword">Email</label>
          <input
            id="emailForPassword"
            name="emailForPassword"
            type="email"
            v-model="email"
            placeholder="Email"
          />
        </div>
        <AppButton
          class="signIn__actions submit-btn emailSubmitBtn"
          type="submit"
        >
          Submit
        </AppButton>
      </form>
    </div>
  </div>
</template>

<script>
import AppButton from "@/components/common/AppButton";
import { useAuthStore } from "@/store/AuthStore";

const authStore = useAuthStore();

export default {
  name: "ForgotPassword",
  components: { AppButton },
  data() {
    return {
      showModal: false,
      email: "",
    };
  },
  methods: {
    async handleSubmit() {
      const data = {
        email: this.email,
      };
      const result = await authStore.forgotUserPassword(data);
      if (result === "success") {
        this.email = "";
        this.showModal = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.modal-backdrop {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.14);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
}
.modal {
  background: #ffffff;
  overflow-x: auto;
  display: flex;
  opacity: 1;
  flex-direction: column;
  max-width: 710px;
  border-radius: 15px;
  @media screen and (max-width: 500px) {
    max-width: 95%;
  }
}
.modal-header {
  background: var(--buzz-charging-dark-blue);
  color: #fff;
  font: normal normal bold 40px Futura;
  text-align: center;
  padding: 32px 108px;
  @media screen and (max-width: 500px) {
    padding: 20px;
    font-size: 35px;
  }
}

.modal-body {
  padding: 66px 86px;
  min-width: 100%;
  font: 25px/40px Montserrat;
  color: var(--base-text-color);
  text-align: center;
  line-height: 34px;
  max-width: 450px;
  @media screen and (max-width: 500px) {
    padding: 16px;
    font-size: 18px;
  }
  a {
    word-break: break-word;
  }
}
.forgotPassword {
  text-align: center;
  margin-top: 10px;
  cursor: pointer;
  a {
    text-decoration: none;
    color: inherit;
    &:hover,
    &:focus {
      color: inherit;
    }
  }
}
.formItem {
  display: flex;
  flex-direction: column;
  label {
    margin-bottom: 0.5rem;
  }
  input {
    background-color: #ddd;
    margin-bottom: 1rem;
    border-radius: 5px;
    border: none;
    padding: 15px 20px;
    outline: none;
  }
}
</style>
