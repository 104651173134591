<template>
  <div class="signIn">
    <h2 class="signIn__title">Sign In</h2>
    <p class="signIn__upper-text">Already Have an Installation Scheduled?</p>
    <div class="signIn__section">
      {{ schema.name }}
      <Form
        class="signIn__form"
        @submit="onSubmit"
        :validation-schema="schema"
        @invalid-submit="onInvalidSubmit"
      >
        <div>
          <AppInput
            class="signIn__input"
            name="email"
            type="text"
            placeholder="Email"
          />
          <AppInput
            class="signIn__input"
            name="password"
            type="password"
            placeholder="Password"
          />
        </div>
        <AppButton class="signIn__actions submit-btn" type="submit">
          Sign In
        </AppButton>
        <ForgotPassword />
      </Form>
    </div>
  </div>
</template>

<script>
import AppInput from "@/components/common/AppInput";
import { Form } from "vee-validate";
import * as Yup from "yup";
import AppButton from "@/components/common/AppButton";
import ForgotPassword from "@/components/layouts/ForgotPassword.vue";
import { useAuthStore } from "@/store/AuthStore";
import { useRouter } from "vue-router";

const store = useAuthStore();

export default {
  name: "DashboardSignIn",
  components: { AppButton, AppInput, Form, ForgotPassword },
  setup() {
    const router = useRouter();

    async function onSubmit(val) {
      try {
        await store.login(val);
        await router.push({
          path: "/profile",
        });
      } catch (e) {
        return e;
      }
    }

    function onInvalidSubmit() {
      const submitBtn = document.querySelector(".submit-btn");
      submitBtn.classList.add("invalid");
      setTimeout(() => {
        submitBtn.classList.remove("invalid");
      }, 1000);
    }

    const schema = Yup.object().shape({
      email: Yup.string().email().required(),
      password: Yup.string().min(6).required(),
    });

    return {
      onInvalidSubmit,
      onSubmit,
      schema,
    };
  },
};
</script>

<style lang="scss" scoped>
.signIn {
  padding: 80px 55px;
  position: relative;
  border-radius: 25px;
  color: #fff;
  background: var(--buzz-charging-dark-blue);
  min-height: 425px;
  max-height: 425px;
  max-width: 460px;
  height: 100%;
  width: 100%;
  @media screen and (max-width: 550px) {
    padding: 40px 24px;
  }

  &__title {
    font: normal normal bold 30px/68px Montserrat;
    margin-bottom: 8px;
  }

  &__upper-text {
    content: "";
    position: absolute;
    pointer-events: all;
    top: -24px;
    transform: translateY(-50%);
    left: 24px;
    right: 0;
    font: normal normal 600 20px/24px Montserrat;
    color: var(--base-text-color);
  }
  &__section {
    width: 100%;
  }

  &__input {
    width: 100%;
  }

  &__actions {
    margin: 0 auto;
    max-width: 250px;
    width: 100%;
    padding: 16px 64px;
  }
}
</style>
