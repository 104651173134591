<template>
  <transition name="modal-fade">
    <div class="modal-backdrop" @click="close">
      <div
        class="modal"
        role="dialog"
        aria-labelledby="modalTitle"
        aria-describedby="modalDescription"
        @click.stop
      >
        <header class="modal-header" id="modalTitle">
          <div class="closeBtn" @click="close"></div>
          <h2>STANDARD INSTALLATION</h2>
        </header>

        <section class="modal-body" id="modalDescription">
          <p>
            A standard installation is any job that only requires up to 20 feet
            of wiring and conduit from the electrical panel to the charging
            station.
          </p>
          <br />
          <p>A standard installation includes:</p>
          <br />
          <ul>
            <li>Installation of a Level 2 hardwired charging station.</li>
            <li>Wiring and conduit for up to 20 feet.</li>
            <li>Load calculation of electrical panel.</li>
            <li>New, installed breaker in your electrical panel.</li>
            <li>Permit and passed inspection.</li>
            <li>90-day service guarantee.</li>
          </ul>
          <p>
            Any job that needs additional wiring and conduit, panel upgrades,
            trenching, or any other additional work variances outside of simple
            electrical work, can expect an adjusted final quote.
          </p>
          <br />
          <p>
            If your installation isn’t “standard”, don’t worry, our system is
            set up so that the consumer can rest-assured that the add-on charges
            are necessary and not above market pricing. You will still receive
            the best pricing available to suit your needs and ensure that your
            installation is done both efficiently and safely. Additional costs
            will apply for non-standard add-ons.
          </p>
          <br />
          <p>What’s not included in a standard installation:</p>
          <br />
          <ul>
            <li>
              Replacement of drywall, stucco, plaster, concrete or any other
              repairs to walls, ceilings or floors if penetration is required
              for wiring runs.
            </li>
            <li>Trenching required to run wire to a detached garage.</li>
            <li>Additional wiring over 20 feet.</li>
            <li>Service upgrades and sub panel installations</li>
          </ul>
          <p>
            Before we can confirm the date of your installation and validate a
            standard install, we will need you to upload a few photos.
          </p>
          <br />
          <p>Required Photos:</p>
          <br />
          <ul>
            <li>Main electrical panel, from 15-20 feet. away</li>
            <li>Inside of your electrical panel, close up</li>
            <li>If you have a sub panel, a photo from 15-20 feet away</li>
            <li>If you have a sub panel, a close-up photo</li>
            <li>The desired location of the charger, from 15-20 feet away</li>
          </ul>
          <AppButton class="confirmBtn" @click="close"
            ><span>Got it</span></AppButton
          >
        </section>
      </div>
    </div>
  </transition>
</template>

<script>
import AppButton from "../common/AppButton.vue";
export default {
  name: "AppInstallationModal",
  components: { AppButton },
  methods: {
    close() {
      this.$emit("close");
    },
  },
};
</script>

<style scoped lang="scss">
.modal-backdrop {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.14);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
}

.modal {
  background: #ffffff;
  // overflow-x: auto;
  display: flex;
  opacity: 1;
  flex-direction: column;
  max-width: 1364px;
  border-radius: 15px;
  @media screen and (max-width: 1400px) {
    max-width: 85%;
  }
  // @media screen and (max-width: 500px) {
  //   max-width: 90%;
  // }
}
.modal-header {
  position: relative;
  background: var(--buzz-charging-dark-blue);
  border-radius: 15px 15px 0px 0px;
  color: #fff;
  font: normal normal bold 40px Futura;
  text-align: center;
  padding: 32px 108px;
  .closeBtn {
    position: absolute;
    right: 0;
    top: 0;
    transform: translate(50%, -50%);
    width: 70px;
    height: 70px;
    border-radius: 50%;
    background: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    @media screen and (max-width: 500px) {
      width: 35px;
      height: 35px;
    }
    &::before,
    &::after {
      content: "";
      position: absolute;
      width: 40px;
      height: 8px;
      background: var(--buzz-charging-dark-blue);
      @media screen and (max-width: 500px) {
        width: 20px;
        height: 4px;
      }
    }
    &::before {
      transform: rotate(45deg);
    }
    &::after {
      transform: rotate(-45deg);
    }
  }
  @media screen and (max-width: 500px) {
    padding: 20px;
    font-size: 30px;
  }
}

.modal-body {
  padding: 66px 86px;
  min-width: 100%;
  font: 25px/40px Montserrat;
  color: var(--base-text-color);
  line-height: 34px;
  max-width: 1364px;
  max-height: 604px;
  overflow: auto;
  @media screen and (max-width: 500px) {
    padding: 20px;
  }
  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    border-radius: 20px;
    background-color: #f5f5f5;
  }
  &::-webkit-scrollbar {
    width: 17px;
    border-radius: 15px;
    background-color: #f5f5f5;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 20px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: var(--base-text-color);
  }
  ul {
    list-style: unset;
    list-style-position: inside;
    margin-bottom: 35px;
    li {
      margin-bottom: 12px;
      line-height: 1.4;
      font: normal normal 400 25px/40px Montserrat;
      @media screen and (max-width: 500px) {
        font-size: 22px;
        line-height: 1.2;
      }
      &::marker {
        margin-right: 2px;
      }
    }
  }
  .confirmBtn {
    width: auto;
    margin: 0 auto;
    padding: 15px 150px;
    @media (max-width: 768px) {
      width: 100%;
      padding: 15px 0;
    }
    span {
      font-size: 25px;
    }
  }
}

.modal-fade-enter,
.modal-fade-leave-to {
  opacity: 0;
}

.modal-fade-enter-active,
.modal-fade-leave-active {
  transition: opacity 0.5s ease;
}
a {
  color: var(--buzz-charging-dark-blue);
  text-decoration: none;
}
</style>
