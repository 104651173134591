<template>
  <DefaultLayout>
    <div class="dashboard">
      <div class="dashboard__wrapper">
        <div class="dashboard__scheduleWrapper">
          <div class="dashboard__schedule" @click="moveToScheduleInstallation">
            <h2 class="dashboard__schedule-title">
              Schedule Your Installation
            </h2>
            <span class="dashboard__schedule-icon"></span>
          </div>
          <!-- <div
            class="standartInstallationBlock"
            @click="showInstallationModal = true"
          >
            What is a <span>Standard Installation?</span>
          </div> -->
        </div>
        <DashboardSignIn v-if="!isLoggedIn" />
      </div>
    </div>
    <AppInstallationModal
      v-if="showInstallationModal"
      @close="showInstallationModal = false"
    />
  </DefaultLayout>
</template>

<script>
import DefaultLayout from "@/views/layouts/default";
import DashboardSignIn from "@/components/dashboard/DashboardSignIn";
import AppInstallationModal from "@/components/layouts/AppInstallationModal";
import { computed, ref } from "vue";
import { useRouter } from "vue-router";
import { useAuthStore } from "@/store/AuthStore";
import { useScheduleStore } from "@/store/ScheduleStepsData";

const stepsStore = useScheduleStore();

const userStore = useAuthStore();

export default {
  name: "Dashboard",
  components: { DashboardSignIn, DefaultLayout, AppInstallationModal },
  setup() {
    const router = useRouter();
    const persistStep = computed(() => {
      try {
        const step = JSON.parse(localStorage.getItem("schedule")).scheduleStep;
        if (
          JSON.parse(localStorage.getItem("authenticated")) &&
          (step === 1 || step === 20)
        ) {
          return 3;
        } else {
          return step;
        }
      } catch (e) {
        return 1;
      }
    });

    const moveToScheduleInstallation = () => {
      stepsStore.setStep(persistStep.value);
      router.push({
        path: `/schedule`,
      });
    };

    const isLoggedIn = computed(() => {
      return userStore.authenticated;
    });
    const showInstallationModal = ref(false);

    return {
      moveToScheduleInstallation,
      isLoggedIn,
      showInstallationModal,
    };
  },
};
</script>

<style lang="scss" scoped>
.dashboard {
  margin: 0 auto;
  width: 100%;

  &__title {
    text-transform: uppercase;
    @media screen and (max-width: 500px) {
      font-size: 25px;
      line-height: 1.2;
      margin-top: 10px;
    }
  }
  &__wrapper {
    display: flex;
    justify-content: space-around;

    @media screen and (max-width: 1024px) {
      flex-direction: column;
      align-items: center;
    }
    @media screen and (max-width: 768px) {
      margin-top: 90px;
    }
    @media screen and (max-width: 500px) {
      margin-top: 40px;
    }
  }
  &__scheduleWrapper {
    @media screen and (max-width: 1024px) {
      margin-bottom: 100px;
    }
  }
  &__schedule {
    padding: 80px 55px 60px 55px;
    color: #fff;
    text-align: center;
    min-height: 425px;
    max-width: 460px;
    max-height: 425px;
    height: 100%;
    width: 100%;
    margin-bottom: 23px;
    background: var(--buzz-charging-dark-blue);
    border-radius: 25px;
    cursor: pointer;
    @media screen and (max-width: 1024px) {
      // margin-bottom: 100px;
    }
    @media screen and (max-width: 500px) {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      min-height: auto;
      padding: 55px;
    }

    &-title {
      font: normal normal bold 30px/68px Montserrat;
      line-height: 40px;
      margin-bottom: 24px;
    }

    &-icon {
      background-image: url("/images/calendar.svg");
      background-repeat: no-repeat;
      width: 180px;
      height: 180px;
      color: #fff;
      display: inline-block;
      @media screen and (max-width: 500px) {
        background-size: contain;
        max-width: 100px;
        max-height: 100px;
      }
    }
  }
}
.standartInstallationBlock {
  text-align: center;
  font: normal normal 600 20px/24px Montserrat;
  color: var(--base-text-color);
  cursor: pointer;
  span {
    color: var(--buzz-charging-dark-blue);
    text-decoration: underline;
    font-style: italic;
  }
}
svg {
  fill: #fff;
}
</style>
